import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Typography, Button } from '@mui/material'

import { theme } from '../../themes/theme'
import { useWindowDimensions } from '../../utilities/getWindowDimensions'
import { groupSessions } from '../../utilities/groupSessions'
import Section from '../_common/Section'
import HomeSessionsItem from './HomeSessionsItem'

const HomeSessions = () => {
	const media = useSelector(state => state.rootReducer.media.data)
	const sessions = groupSessions(media)?.slice(0, 5)
	const { width } = useWindowDimensions()
	const styles = {
		wrapper: {
			zIndex: 2,
			display: 'flex',
			flexDirection: 'column',
		},
		list: {
			display: 'flex',
			gap: 1.5,
			marginRight: -3,
			marginBottom: -3,
			paddingRight: 3,
			paddingBottom: 3,
			overflowX: 'auto',
			'&::-webkit-scrollbar': { display: 'none', },
			[theme.breakpoints.down('md')]: {
				marginLeft: -2.5,
				marginRight: -2.5,
				paddingLeft: 2.5,
				paddingRight: 2.5,
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: -2,
				marginRight: -2,
				paddingLeft: 2,
				paddingRight: 2,
			},
		},
		corners: {
			position: 'absolute',
			top: -0.5,
			left: 0,
			bottom: -0.5,
			zIndex: 3,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			[theme.breakpoints.down('md')]: { display: 'none', },
		},
		corner: {
			height: 24,
			aspectRatio: '1 / 1',
		},
	}

	const heading = <Typography variant={width > 992 ? 'heading2' : 'heading3'} children='Recent Sessions' />

	const button = (
		<Button variant='text' color='secondary' size='small' component={Link} to='/media?tab=sessions' disableRipple>
			<Typography variant='button1' children='See all'/>
			<svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewBox="0 0 448 512"><path fill={theme.palette.text.primary} d="M429.8 273l17-17-17-17L276.2 85.4l-17-17-33.9 33.9 17 17L354.9 232 24 232 0 232l0 48 24 0 330.8 0L242.2 392.6l-17 17 33.9 33.9 17-17L429.8 273z"/></svg>
		</Button>
	)

	const corners = (
		<Box sx={styles.corners}>
			<Box sx={styles.corner}>
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60"><path fill={theme.palette.background.default} d="M60 0H0V60C0 26.8628 26.8628 0 60 0Z"/></svg>
			</Box>
			<Box sx={{ ...styles.corner, transform: 'rotate(180deg)', }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60"><path fill={theme.palette.background.default} d="M0 0H60V60C60 26.8628 33.1372 0 0 0Z"/></svg>
			</Box>
		</Box>
	)

	const sessionsItems = sessions?.map((session, index) => <HomeSessionsItem key={index} session={session} />)

	const sessionsList = <Box sx={styles.list}>{sessionsItems}</Box>

	return (
		<Box sx={styles.wrapper}>
			<Section
				headHeight={width < 992 ? 56 : 70}
				heading={heading}
				actions={button}
				children={<>{corners}{sessionsList}</>}
			/>
		</Box>
	)
}
 
export default HomeSessions
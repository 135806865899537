import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const SetupForm = ({ title, message, actions, children }) => {
	const styles = {
		head: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			padding: '0 0 24px',
			textAlign: 'center',
		},
		title: { marginBottom: 2, },
		message: { color: theme.palette.text.secondary, },
		body: {
			display: 'grid',
			gridTemplateColumns: 'repeat(6, 1fr)',
			gap: 2,
			width: '100%',
		},
		foot: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			gap: 1,
			width: '100%',
			padding: '24px 0 0',
		},
	}

  return (
    <>
      <Box sx={styles.head}>
        <Typography variant='title2' sx={styles.title}>{title}</Typography>
        <Typography variant='subtitle2' sx={styles.message}>{message}</Typography>
      </Box>
      <Box sx={styles.body}>{children}</Box>
      <Box sx={styles.foot}>{actions}</Box>
    </>
  )
}

export default SetupForm
import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import AccountSignOut from './AccountSignOut'
import AccountIdCard from './AccountIdCard'
import AccountProfileForm from './AccountProfileForm'
import AccountDelete from './AccountDelete'

const AccountProfile = () => {
	const styles = {
		wrapper: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
		},
		main: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			background: theme.palette.background.paper,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 4,
		},
		head: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingLeft: 3,
			paddingRight: 3,
			height: 64,
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		body: {},
	}

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.main}>
				<Box sx={styles.head}>
					<Typography variant='heading3'>My Profile</Typography>
					<AccountSignOut />
				</Box>
				<Box sx={styles.body}>
					<AccountIdCard />
					<AccountProfileForm />
				</Box>
			</Box>
			<AccountDelete />
		</Box>
	)
}
 
export default AccountProfile
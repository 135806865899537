import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import emptyGraphic from '../../assets/images/empty_media.svg'
import retroactiveGraphic from '../../assets/images/retroactive_media.svg'

const MediaStatusView = ({ status }) => {
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			gap: status === 'retroactive' ? 3 : 4,
			height: '100%',
			textAlign: 'center',
		},
		graphic: {},
		main: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 4,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			gap: 1.5,
		},
	}

	let graphic
	let heading
	let message
	let button

	switch (status) {
		case 'retroactive':
			graphic = retroactiveGraphic
			heading = `You've got media`
			message = `Activate your account to view it here.`
			button = <Button component={Link} to='/tag' variant='contained' color='secondary' size='large' children='Activate my account ⚡️' />
			break
		default:
			graphic = emptyGraphic
			heading = `No media`
			message = `Go to My Tag to get started.`
			button = null
	}

	return (
		<Box sx={styles.wrapper} className='status'>
			<Box sx={styles.graphic}>
				<img src={graphic} alt={heading} />
			</Box>
			<Box sx={styles.main}>
				<Box sx={styles.content}>
					<Typography variant='heading1' sx={{ color: theme.palette.text.secondary, }} children={heading} />
					<Typography variant='body1' sx={{ color: theme.palette.text.secondary, }} children={message} />
				</Box>
				{button}
			</Box>
		</Box>
	)
}
 
export default MediaStatusView
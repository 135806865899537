import { alpha, Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { theme } from '../../themes/theme'
import { getMediaThumbnail } from '../../utilities/getMediaThumbnail'

const MediaSessionsItem = ({ session, onSessionClick }) => {
  const date = dayjs(session?.date).format('MMM D')
  const media = session?.media
  const mediaItem = media.sort((a, b) => b.datetime - a.datetime)?.[0]
  const location = session?.locationNames?.length < 2 ? session?.locationNames[0] : 'Multiple locations'
	const thumbnail = getMediaThumbnail(mediaItem)
	const styles = {
		wrapper: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			aspectRatio: '1 / 1',
			cursor: 'pointer',
			transition: 'opacity 0.2s ease-out',
			borderRadius: 2.5,
			'&:hover': { opacity: 0.9, },
		},
		corners: {
			position: 'sticky',
			top: 69.5,
			left: 0,
			right: 0,
			zIndex: 3,
			display: 'flex',
			justifyContent: 'space-between',
			marginLeft: -0.05,
			marginRight: -0.05,
			height: 0,
			[theme.breakpoints.down('md')]: { height: 12, },
		},
		corner: {
			position: 'absolute',
			top: 0,
			height: 20,
			aspectRatio: '1 / 1',
		},
		thumbnailWrapper: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1,
			width: '100%',
			height: '100%',
			borderRadius: 5,
			overflow: 'hidden',
			'& span': {
				width: '100%',
				height: '100%',
			},
		},
		thumbnail: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 1,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		content: {
			flex: 1,
			position: 'relative',
			zIndex: 2,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'flex-end',
			paddingLeft: 2,
			paddingRight: 2,
			paddingBottom: 2,
			width: '100%',
			background: `linear-gradient(${alpha(theme.palette.background.darker, 0)} 50%, ${alpha(theme.palette.background.darker, 0.7)})`,
			borderRadius: '20px',
		},
		info: {
			display: 'flex',
			flexDirection: 'column',
			gap: 0.5,
			width: '100%',
		},
		date: {
			lineHeight: 1,
			color: theme.palette.text.inverse,
		},
		details: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		detail: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
			'& p': { color: theme.palette.text.inverse, },
		},
	}
  
  return (
    <Box sx={styles.wrapper} onClick={() => onSessionClick(`${session?.date.replace(/\s/g, '')}`)}>
      <Box sx={styles.corners}>
        <Box sx={{ ...styles.corner, left: 0, }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60"><path fill={theme.palette.background.default} d="M60 0H0V60C0 26.8628 26.8628 0 60 0Z"/></svg>
        </Box>
        <Box sx={{ ...styles.corner, right: 0, }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 60 60"><path fill={theme.palette.background.default} d="M0 0H60V60C60 26.8628 33.1372 0 0 0Z"/></svg>
        </Box>
      </Box>
      <Box sx={styles.thumbnailWrapper}>
        <LazyLoadImage style={styles.thumbnail} src={thumbnail?.path} effect='blur' alt='Captured media' />
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.info}>
          <Typography variant='title1' sx={styles.date}>{date}</Typography>
          <Box sx={styles.details}>
            <Box sx={styles.detail}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ height: 18, width: 18 }} viewBox="0 0 17 20" className='nav-icon'><path fill={theme.palette.text.inverse} fillRule="evenodd" clipRule="evenodd" d="M14.4893 13.5212C15.7036 12 16.7 10.1409 16.7 8C16.7 3.5817 13.1183 0 8.69998 0C4.28168 0 0.699982 3.5817 0.699982 8C0.699982 10.1409 1.69788 12 2.91058 13.5212L8.69998 20L14.4893 13.5212ZM8.69998 10.5C10.0807 10.5 11.2 9.3807 11.2 8C11.2 6.6193 10.0807 5.5 8.69998 5.5C7.31928 5.5 6.19998 6.6193 6.19998 8C6.19998 9.3807 7.31928 10.5 8.69998 10.5Z" /></svg>
              <Typography sx={{ fontSize: 16 }}>{location}</Typography>
            </Box>
            <Box sx={styles.detail}>
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 21 20"><path fill={theme.palette.text.inverse} d="M4.8 0.5H15.8V2H4.8V0.5ZM2.3 3H18.3V5H2.3V3Z"></path><path fill={theme.palette.text.inverse} fillRule="evenodd" clipRule="evenodd" d="M0.299995 6H20.3V19.5H0.299995V6ZM7.8 11L12.3 15.5L14.8 13L17.8 16V17H2.8V16L7.8 11Z"></path></svg>
              <Typography sx={{ fontSize: 13, fontWeight: 600 }}>{media?.length}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MediaSessionsItem
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, Avatar, Typography, IconButton } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { theme } from '../../themes/theme'
import AppBar from '../_common/AppBar'
import HomeHeadPopover from './HomeHeadPopover'

const HomeHead = () => {
	const user = useSelector(state => state.rootReducer.user.data)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const styles = {
		greeting: {
			display: 'flex',
			alignItems: 'center',
			gap: 1.25,
			paddingLeft: 0.5,
		},
		avatar: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			width: 40,
			height: 40,
			objectFit: 'cover',
			borderRadius: '50%',
			overflow: 'hidden',
			backgroundColor: theme.palette.background.dark,
			'& span': {
				width: '100%',
				height: '100%',
			},
		},
		avatarImage: {
			position: 'absolute',
			top: 0,
			left: 0,
			zIndex: 1,
			width: '100%',
			height: '100%',
		},
	}

	const handleOpenPopover = (e) => setAnchorEl(e.currentTarget)

	return (
		<AppBar
			left={
				<Box sx={styles.greeting}>
					<Link to='/account'>
						<Avatar sx={styles.avatar}>
							{user?.avatar
								? <LazyLoadImage style={styles.avatarImage} src={user.avatar} effect='blur' alt={`${user?.firstName} ${user?.lastName}`} />
								: <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 448 512"><path fill={theme.palette.grey[500]} d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zM448 512L384 304H64L0 512H448z"/></svg>}
						</Avatar>
					</Link>
					<Typography variant='heading1' children={`Hi, ${user?.username}`}/>
				</Box>
			}
			right={
				<>
					<IconButton
						sx={{ borderRadius: '50%', }}
						onClick={handleOpenPopover}
						children={<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 448 512"><path fill={theme.palette.text.primary} d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/></svg>}
					/>
					<HomeHeadPopover open={open} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
				</>
			}
		/>
	)
}
 
export default HomeHead
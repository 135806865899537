import { forwardRef } from 'react'
import { Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const Popup = forwardRef((props, ref) => {
  const title = props.title
  const closer = props.closer
  const actions = props.actions
  const children = props.children
	const styles = {
		popup: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: 480,
			maxWidth: 560,
			borderRadius: 4,
			backgroundColor: theme.palette.background.paper,
			[theme.breakpoints.down('md')]: {
				minWidth: 0,
				width: '100%',
			},
		},
		head: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: 64,
			paddingLeft: 3,
			paddingRight: 3,
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			gap: 2.5,
			padding: 3,
		},
		foot: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
			paddingLeft: 3,
			paddingRight: 3,
			paddingBottom: 3.5,
		},
	}

  return (
    <Box sx={styles.popup} ref={ref}>
      <Box sx={styles.head}>
        <Typography variant='heading5'>{title}</Typography>
        {closer}
      </Box>
      <Box sx={styles.body}>
        {children}
      </Box>
      <Box sx={styles.foot}>
        {actions}
      </Box>
    </Box>
  )
})

export default Popup
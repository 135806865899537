import { useSelector } from 'react-redux'
import { alpha, Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'
import SubscriptionDetails from '../_common/SubscriptionDetails'
import SubscriptionPortalButton from '../_common/SubscriptionPortalButton'
import ActivationTimer from '../_common/ActivationTimer'

const AccountMembership = () => {
  const user = useSelector(state => state.rootReducer.user.data)
	const activationType = user?.activation_type
	const styles = {
		wrapper: {
			position: 'sticky',
			top: 24,
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
			width: '28vw',
			maxWidth: 420,
			[theme.breakpoints.down('md')]: {
				flex: 1,
				alignSelf: 'center',
				position: 'static',
				justifyContent: 'flex-end',
				paddingTop: 4,
				paddingBottom: 5,
				paddingLeft: 0,
				paddingRight: 0,
				width: '100%',
			},
		},
		main: {
			display: 'flex',
			flexDirection: 'column',
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 4,
			backgroundColor: theme.palette.background.paper,
			[theme.breakpoints.down('md')]: {
				border: 'none',
				borderRadius: 0,
				backgroundColor: 'transparent',
			},
		},
		head: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingLeft: 3,
			paddingRight: 3,
			height: 64,
			borderBottom: `1px solid ${theme.palette.divider}`,
			[theme.breakpoints.down('md')]: { display: 'none', },
		},
		body: {
			display: 'flex',
			flexDirection: 'column',
			gap: 2,
			padding: 3,
			[theme.breakpoints.down('md')]: { padding: 0, },
		},
		block: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: 1,
			paddingTop: 4,
			paddingLeft: 4,
			paddingRight: 4,
			paddingBottom: 4,
			borderRadius: 2,
			border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
			backgroundColor: theme.palette.primary.light,
			[theme.breakpoints.down('md')]: {
				paddingTop: 2.5,
				paddingLeft: 2.5,
				paddingRight: 2.5,
				paddingBottom: 2.5,
			},
		},
		membership: {
			[theme.breakpoints.down('sm')]: {
				gap: 2,
				flexDirection: 'row',
			},
		},
		membershipContent: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.down('sm')]: {
				alignItems: 'center',
				textAlign: 'center',
			}
		},
	}

  let detailsMarkup = <></>
	if (user?.activation_status) detailsMarkup = <SubscriptionDetails />
	if (user?.activation_status && activationType === 'Day Pass') detailsMarkup = <ActivationTimer user={user} />

  return (
    <Box sx={styles.wrapper}>
			<Box sx={styles.main}>
				<Box sx={styles.head}>
					<Typography variant='heading3'>My Membership</Typography>
				</Box>
				<Box sx={styles.body}>
					{detailsMarkup}
					<SubscriptionPortalButton />
				</Box>
			</Box>
    </Box>
  )
}

export default AccountMembership
import NavMinor from './NavMinor'

import { theme } from '../../../themes/theme'

const NavMinorList = () => {
	return (
		<>
			<NavMinor label='How to use Hightag' to='https://www.hightag.com/guides/usage-basic' icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 512 512"><path fill={theme.palette.text.secondary} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216 192V224h24 48 24v24 88h8 24v48H296 216 192V336h24zm72-144H224V128h64v64z"/></svg>} />
			<NavMinor label='Get support' to='https://www.hightag.com/contact/support' icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 512 512"><path fill={theme.palette.text.secondary} d="M256 48C141.1 48 48 141.1 48 256v40 24H0V296 256C0 114.6 114.6 0 256 0S512 114.6 512 256V464.1v24l-24 0L320 488v24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h80v24l144 .1V256c0-114.9-93.1-208-208-208zM192 224V384H80V224H192zm240 0V384H320V224H432z"/></svg>} />
			<NavMinor label='Terms & policies' to='https://www.hightag.com/legal/app-privacy' icon={<svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 448 512"><path fill={theme.palette.text.secondary} d="M224 64c44.2 0 80 35.8 80 80v48H144V144c0-44.2 35.8-80 80-80zM80 144v48H0V512H448V192H368V144C368 64.5 303.5 0 224 0S80 64.5 80 144zM256 320v64 32H192V384 320 288h64v32z"/></svg>} />
		</>
	)
}
 
export default NavMinorList
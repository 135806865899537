import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Fade, Box, Typography } from '@mui/material'

import { theme } from '../../themes/theme'

const ActivationTimer = ({ hideMessage, messageString }) => {
  const user = useSelector(state => state.rootReducer.user.data)
	const activationStatus = user?.activation_status
  const [remainingTime, setRemainingTime] = useState(null)
	const message = messageString || 'Day Pass time remaining'
	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		message: {
			marginBottom: 1,
		},
		timer: {
			display: 'flex',
			gap: '1px',
			width: '100%',
			height: 104,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: 3,
			backgroundColor: theme.palette.divider,
			overflow: 'hidden',
		},
		timerGroup: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.background.default,
			'& *': { lineHeight: '1 !important', },
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
	}

  useEffect(() => {
    const interval = setInterval(() => {
      const timeNow = new Date().getTime()
      const timeExp = user?.activation_expiration?.toDate()?.getTime()
      const timeDif = timeExp - timeNow

      if (timeDif <= 0) {
        clearInterval(interval)
        setRemainingTime(null)
      } else {
        const hours = Math.floor(timeDif / (1000 * 60 * 60))
        const minutes = Math.floor((timeDif % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeDif % (1000 * 60)) / 1000)

				if (hours.isNaN || minutes.isNaN || seconds.isNaN) return
        setRemainingTime({ hours, minutes, seconds })
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [user?.activation_expiration])

	if (activationStatus !== 'active' || activationStatus !== 'trialing') return null

  return (
		<Box sx={styles.wrapper}>
			{!hideMessage && <Typography sx={styles.message} children={message} />}
			<Box sx={styles.timer}>
				<Box sx={styles.timerGroup}>
					<Fade in={remainingTime}>
						<div style={styles.content}>
							<Typography variant='title1'>{remainingTime?.hours}</Typography>
							<Typography variant='body2'>hours</Typography>
						</div>
					</Fade>
				</Box>
				<Box sx={styles.timerGroup}>
					<Fade in={remainingTime}>
						<div style={styles.content}>
							<Typography variant='title1'>{remainingTime?.minutes}</Typography>
							<Typography variant='body2'>minutes</Typography>
						</div>
					</Fade>
				</Box>
				<Box sx={styles.timerGroup}>
					<Fade in={remainingTime}>
						<div style={styles.content}>
							<Typography variant='title1'>{remainingTime?.seconds}</Typography>
							<Typography variant='body2'>seconds</Typography>
						</div>
					</Fade>
				</Box>
			</Box>
		</Box>
	)
}

export default ActivationTimer
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { httpsCallable } from 'firebase/functions'
import { styled, Button, Box, Typography, Switch, CircularProgress } from '@mui/material'

import { functions } from '../../firebase/initialize'
import { theme } from '../../themes/theme'

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 20,
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: theme.palette.text.inverse,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 14,
    height: 14,
    borderRadius: '50%',
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
    boxSizing: 'border-box',
  },
}))

const TagPlansCard = ({
	title,
	plans,
	benefits,
	generalLoading,
	setGeneralLoading,
	includeTrial,
	preferred,
	switcher
}) => {
	const user = useSelector(state => state.rootReducer.user.data)
  const stripeCreateCheckout = httpsCallable(functions, 'stripeCreateCheckout')
	const [selectedIndex, setSelectedIndex] = useState(1)
	const [loading, setLoading] = useState(false)
	const selectedPlan = switcher ? plans[selectedIndex] : plans[0]
	const buttonText = includeTrial ? 'Start free trial' : 'Get started'
	const styles = {
		wrapper: {
			flex: 1,
			position: 'relative',
			display: 'flex',
			flexDirection: 'column',
			paddingTop: 3.5,
			paddingLeft: 3,
			paddingRight: 3,
			paddingBottom: 8,
			maxWidth: 400,
			backgroundColor: preferred ? theme.palette.primary.light : theme.palette.background.default,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: preferred ? '#FFBCAE' : theme.palette.divider,
			borderRadius: 4,
		},
		title: { color: preferred ? theme.palette.primary.dark : theme.palette.heading.primary, },
		switcher: {
			position: 'absolute',
			top: 34,
			right: 24,
			display: 'flex',
			alignItems: 'center',
			gap: 0.75,
			marginBottom: 1,
			padding: 0,
			height: 'auto',
		},
		benefits: {
			display: 'flex',
			flexDirection: 'column',
			gap: 0.75,
			paddingTop: 2,
			paddingBottom: 3,
		},
		benefitsItem: {
			display: 'flex',
			alignItems: 'center',
			gap: 0.75,
		},
		buttonWrapper: {
			position: 'absolute',
			left: 24,
			right: 24,
			bottom: 24,
		},
	}

	const handleToggleSelected = () => selectedIndex === 0 ? setSelectedIndex(1) : setSelectedIndex(0)

	const handleCheckout = async () => {
		setLoading(true)
		setGeneralLoading(true)

		const session = await stripeCreateCheckout({
			userId: user.id,
			customerId: user?.stripeId,
			priceId: selectedPlan.priceId,
			successUrl: `${window.location.origin}/tag?status=succeeded`,
			cancelUrl: `${window.location.origin}/tag?status=cancelled`,
			mode: 'subscription',
			includeTrial: includeTrial,
		})
		if (session?.data?.url) window.location.href = session.data.url
		if (session?.data?.error) console.error(session.data.error)

		setLoading(false)
		setGeneralLoading(false)
	}

	const switcherMarkup = (
		<Button sx={styles.switcher} onClick={handleToggleSelected} disableRipple>
			<Typography variant='body3' children={plans?.[0]?.title} />
			<AntSwitch checked={!!selectedIndex} disableRipple/>
			<Typography variant='body3' children={plans?.[1]?.title} />
		</Button>
	)
	
	return (
		<Box sx={styles.wrapper}>
			<Typography sx={styles.title} variant='title2' children={title} />
			{switcher && switcherMarkup}
			<Typography variant='subtitle2' children={switcher ? plans[selectedIndex].price : plans[0].price} />
			<Box sx={styles.benefits}>
				{benefits?.map((benefit, index) => (
					<Box sx={styles.benefitsItem} key={index}>
						<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 512 512"><path fill={preferred ? theme.palette.primary.main : theme.palette.text.primary} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM241 337l-17 17-17-17-80-80L161 223l63 63L351 159 385 193 241 337z"/></svg>
						<Typography variant='body3' children={benefit} />
					</Box>
				))}
			</Box>
			<Box sx={styles.buttonWrapper}>
				<Button variant='contained' color='primary' onClick={handleCheckout} disabled={generalLoading} fullWidth disableRipple>
					{loading
						? <CircularProgress color='secondary' size={24} />
						: <Typography variant='button1' children={buttonText} />}
				</Button>
			</Box>
		</Box>
	)
}
 
export default TagPlansCard
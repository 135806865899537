import { palette } from './palette'

export const typography = {
  typography: {
    allVariants: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      lineHeight: 1.4,
      letterSpacing: '0em',
      textTransform: 'none',
    },
    title1: {
      fontSize: 48,
			lineHeight: 1.1,
      fontFamily: '"Bebas Neue", sans-serif',
      color: palette.palette.heading.primary,
    },
    title2: {
			lineHeight: 1.1,
      fontSize: 32,
      fontFamily: '"Bebas Neue", sans-serif',
      color: palette.palette.heading.primary,
    },
    subtitle1: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 600,
      fontSize: 19,
      color: palette.palette.text.primary,
    },
    subtitle2: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 600,
      fontSize: 17,
      color: palette.palette.text.primary,
    },
    subtitle3: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 600,
      fontSize: 16,
      color: palette.palette.text.primary,
    },
    subtitle4: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 600,
      fontSize: 13.5,
      color: palette.palette.text.primary,
    },
    heading1: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 700,
      fontSize: 26,
      lineHeight: 1.1,
      letterSpacing: '-0.02em',
      color: palette.palette.heading.primary,
    },
    heading2: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 700,
      fontSize: 23,
      lineHeight: 1.1,
      letterSpacing: '-0.02em',
      color: palette.palette.heading.primary,
    },
    heading3: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 700,
      fontSize: 21,
      lineHeight: 1.1,
      letterSpacing: '-0.02em',
      color: palette.palette.heading.primary,
    },
    heading4: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 700,
      fontSize: 19,
      lineHeight: 1.1,
      letterSpacing: '-0.02em',
      color: palette.palette.heading.primary,
    },
    heading5: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 600,
      fontSize: 17,
      lineHeight: 1.1,
      color: palette.palette.heading.primary,
    },
    body1: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      fontSize: 17,
      lineHeight: 1.4,
      color: palette.palette.text.primary,
    },
    body2: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      fontSize: 15,
      lineHeight: 1.4,
      color: palette.palette.text.primary,
    },
    body3: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      fontSize: 13.5,
      lineHeight: 1.4,
      color: palette.palette.text.primary,
    },
    body4: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      fontSize: 12.5,
      lineHeight: 1.4,
      color: palette.palette.text.primary,
    },
    button1: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.4,
    },
    button2: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: 1.4,
    },
    overline: {
      fontFamily: '"Wix", sans-serif',
      fontWeight: 600,
      fontSize: 13,
      lineHeight: 1.4,
      color: palette.palette.text.primary,
    }
  },
}
import { useSelector } from 'react-redux'
import { doc, updateDoc } from 'firebase/firestore'
import { Button, Card, CardActions, CardContent, CardHeader, Fade, IconButton, Modal, Typography } from '@mui/material'

import { db } from '../../firebase/initialize'
import { theme } from '../../themes/theme'

const TagAliasModal = ({ modalOpen, setModalOpen }) => {
  const tags = useSelector(state => state.rootReducer.tags.data)
	const styles = {
		wrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			paddingLeft: 3,
			paddingRight: 3,
		},
	}

  const handleUnlink = async () => {
    const tagDocRef = doc(db, 'tags', tags?.[0].id)
    setModalOpen(false)
    await updateDoc(tagDocRef, { user_id: null })
  }

  const handleClose = () => setModalOpen(false)

  return (
    <Modal sx={styles.wrapper} open={modalOpen} onClose={handleClose}>
      <Fade in={modalOpen}>
        <div>
          <Card sx={{ maxWidth: 400 }}>
            <CardHeader
							title={<Typography variant='heading5'>Unlink tag</Typography>}
							action={
								<IconButton onClick={handleClose}>
									<svg xmlns="http://www.w3.org/2000/svg" height="1.1em" viewBox="0 0 384 512"><path fill={theme.palette.text.primary} d="M345 137l17-17L328 86.1l-17 17-119 119L73 103l-17-17L22.1 120l17 17 119 119L39 375l-17 17L56 425.9l17-17 119-119L311 409l17 17L361.9 392l-17-17-119-119L345 137z"/></svg>
								</IconButton>
							}
            />
            <CardContent>
              <Typography variant='body3'>Are you sure you want to unlink this tag? This won't affect your account's activation status.</Typography>
            </CardContent>
            <CardActions>
              <Button variant='contained' color='secondary' size='small' onClick={handleUnlink} disableRipple>Yes, unlink it</Button>
              <Button variant='outlined' color='inherit' size='small' onClick={handleClose} disableRipple>Cancel</Button>
            </CardActions>
          </Card>
        </div>
      </Fade>
    </Modal>
  )
}
 
export default TagAliasModal